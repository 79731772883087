import React, { useEffect, useState } from 'react';
import InvoiceItem from './InvoiceItem';
import '../styles/CustomerInvoices.css';
import { formatDateToZoho } from '../assets/Utils';
import axios from 'axios';
import * as XLSX from 'xlsx'; // Import the XLSX library for Excel export

const CustomerInvoices = ({ invoicesData, customerName, startDate, endDate }) => {
  const [groupedInvoices, setGroupedInvoices] = useState({});
  const [overallTotal, setOverallTotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalBeforeDiscount, setTotalBeforeDiscount] = useState(0);
  const [dateRangeText, setDateRangeText] = useState('');

  useEffect(() => {
    const groupData = () => {
      const grouped = {};
      setDateRangeText(
        formatDateToZoho(startDate.toISOString().split('T')[0]) + ' - ' + formatDateToZoho(endDate.toISOString().split('T')[0])
      );
      invoicesData.map((invoice, index) => {
        const key = `${invoice.patient_name} (ID-${invoice.patient_id})`;

        if (!grouped[key]) {
          grouped[key] = [];
        }

        grouped[key].push({ invoice });
      });

      setGroupedInvoices(grouped);
    };

    const calculateOverallTotal = () => {
      const total = invoicesData.reduce((acc, invoice) => acc + parseFloat(invoice.sales_total), 0);
      const beforeDiscount = invoicesData.reduce((acc, invoice) => acc + parseFloat(invoice.sales_before_adjustments), 0);
      const discount = beforeDiscount - total;
      setOverallTotal(total);
      setTotalDiscount(discount);
      setTotalBeforeDiscount(beforeDiscount);
    };

    if (invoicesData.length > 0 && customerName && startDate && endDate) {
      console.log("Customer Invoice Start Date: ",startDate.toISOString().split('T')[0]);
      groupData();
      calculateOverallTotal();
    }
  }, [invoicesData, customerName, startDate, endDate]);

  // Handle print action
  const handlePrint = () => {
    window.print();
  };

  // Handle Excel export
  const handleExcelExport = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_SALES_TRANSACTIONS}`, {
        params: {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          customerName: customerName,
          status: 'unpaid',
        },
      });

      const data = response.data;

      // Convert the data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');

      // Export the workbook as an Excel file
      XLSX.writeFile(workbook, `CustomerInvoices_${customerName}_${startDate.toISOString().split('T')[0]}_to_${endDate.toISOString().split('T')[0]}.xlsx`);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };

  return (
    <div className="printWrapper">
      {/* Add print and excel buttons */}
      <div className="button-container">
        <button className="print-button exclude-from-pdf" onClick={handlePrint}>
          Print
        </button>
        <button className="excel-button exclude-from-pdf" onClick={handleExcelExport}>
          Excel
        </button>
      </div>

      <div className="list-invoices">
        <div id="summaryHeader">
          <h2 className="hospital-name">Shuman Hospital</h2>
          <div className="address">
            <h3>15-17 Pultney Street Freetown</h3>
            <h3>billing@shumanhospital.com</h3>
            <h3>Tel: 022-224224</h3>
          </div>
          <h2 className="customer-name">{customerName}</h2>
          <h3 className="dateRange">{dateRangeText}</h3>
        </div>
        <div id="totalSummary">
          <table className="summaryTotalsTable">
            <tbody>
              <tr>
                <td>Total:</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{+parseFloat(totalBeforeDiscount).toFixed(1).toLocaleString(undefined, { maximumFractionDigits: 2 })}</b></td>
              </tr>
              <tr>
                <td>Discount:</td>
                <td>(-)&nbsp;<b>{parseFloat(totalDiscount).toFixed(1).toLocaleString()}</b></td>
              </tr>
              <tr>
                <td>Total Due:</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;<b>{parseFloat(overallTotal).toFixed(1).toLocaleString()}</b></td>
              </tr>
              <tr>
                <td>Number of Invoices:</td>
                <td>{invoicesData.length}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <table id="mainExportTable" className="summaryTable">
          <thead>
            <tr>
              <th className="patientCellHeader">Patient Name</th>
              <th className="dateCellHeader">Date</th>
              <th className="itemsCellHeader">Detail</th>
              <th className="dateTotalCellHeader">Total</th>
              <th className="dateTotalCellHeader">After Discount</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedInvoices).map((key) => (
              <tr className="patientRecord" key={key}>
                <td className="patientCell">{key}</td>
                <td colSpan={4} className="invoiceCell">
                  <table className="itemsTable">
                    <tbody>
                      {groupedInvoices[key].map((item, index) => (
                        <tr className="visitRecord" key={index}>
                          <td className="dateCell">{item.invoice.sales_date}</td>
                          <td className="itemsCell">
                            <InvoiceItem invoice={item.invoice} />
                          </td>
                          <td className="dateTotalCell">{item.invoice.sales_before_adjustments}</td>
                          <td className="dateTotalCell">{item.invoice.sales_total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerInvoices;
