import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import '../styles/CustomerAgingSummary.css'; // Assuming you have a CSS file for styling

const CustomerAgingSummary = () => {
  const [agingData, setAgingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch customer aging summary data
    const fetchAgingSummary = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/customers/customer-aging/summary`);
        setAgingData(response.data);
      } catch (error) {
        console.error('Error fetching aging summary data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgingSummary();
  }, []);

  // Format number with no decimal and thousands separator
  const formatNumber = (num) => {
    return parseInt(num).toLocaleString();
  };

  // Calculate totals for the columns
  const calculateTotals = (key) => {
    return formatNumber(agingData.reduce((acc, item) => acc + parseFloat(item[key] || 0), 0));
  };

  // Data Table Function to help sort numeric columns properly
  const numericSort = (rowA, rowB, field) => {
    const valueA = parseFloat(rowA[field]) || 0;
    const valueB = parseFloat(rowB[field]) || 0;
    return valueA - valueB;
  };

  // Define columns for the DataTable
  const columns = [
    { 
        name: 'Customer Name', 
        selector: (row) => row.customer_name, 
        sortable: true 
    },
    { 
        name: 'Invoiced', 
        selector: (row) => formatNumber(row.INVOICED), 
        sortable: true, 
        right: true,
        sortFunction: (rowA, rowB) => numericSort(rowA, rowB, 'INVOICED') 
    },
    { 
        name: 'Payment', 
        selector: (row) => formatNumber(row.PAYMENT), 
        sortable: true, 
        right: true,
        sortFunction: (rowA, rowB) => numericSort(rowA, rowB, 'PAYMENT') 
    },
    { 
        name: 'Balance', 
        selector: (row) => formatNumber(row.BALANCE), 
        sortable: true, 
        right: true,
        sortFunction: (rowA, rowB) => numericSort(rowA, rowB, 'BALANCE') 
    },
    { 
        name: 'Last Payment Date', 
        selector: (row) => row.last_payment_date, 
        sortable: true
    },
    { 
        name: 'Older Than 3 Months', 
        selector: (row) => formatNumber(row.older_than_3_months_balance),
        conditionalCellStyles: [
      {
        when: row => row.older_than_3_months_balance > 5000,
        style: {
          backgroundColor: 'orange',
          color: 'black',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
        ], 
        sortable: true, 
        right: true,
        sortFunction: (rowA, rowB) => numericSort(rowA, rowB, 'older_than_3_months_balance')
    },
    { 
        name: 'Older Than 2 Months', 
        selector: (row) => formatNumber(row.older_than_2_months_balance), 
        sortable: true, right: true ,
        sortFunction: (rowA, rowB) => numericSort(rowA, rowB, 'older_than_2_months_balance')
    },
    { 
        name: 'Older Than 1 Month', 
        selector: (row) => formatNumber(row.older_than_1_month_balance), 
        sortable: true, right: true,
        sortFunction: (rowA, rowB) => numericSort(rowA, rowB, 'older_than_1_months_balance')
    },
  ];

  // Data for CSV export
  const csvData = agingData.map((row) => ({
    'Customer Name': row.customer_name,
    'Invoiced': formatNumber(row.INVOICED),
    'Payment': formatNumber(row.PAYMENT),
    'Balance': formatNumber(row.BALANCE),
    'Last Payment Date': row.last_payment_date,
    'Older Than 3 Months': formatNumber(row.older_than_3_months_balance),
    'Older Than 2 Months': formatNumber(row.older_than_2_months_balance),
    'Older Than 1 Month': formatNumber(row.older_than_1_month_balance),
    'Last Invoice Date': row.last_invoice_date,
    'Last Payment Amount': formatNumber(row.last_payment_amount),
    'Number of Visits (YTD)': formatNumber(row.total_visits),
    'Average Monthly Sales': formatNumber(row.average_monthly_sales),
    'Average Spend Per Visit': formatNumber(row.average_spend_per_visit),
  }));

  // Function to render expanded content for each row
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="expanded-content" style={{ marginLeft: '20px' }}>
        <div className="expanded-row" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ flex: '1 1 30%', margin: '10px' }}>
            <p><strong>Last Invoice Date:</strong> {data.last_invoice_date}</p>
            <p><strong>Last Payment Date:</strong> {data.last_payment_date}</p>
          </div>
          <div style={{ flex: '1 1 30%', margin: '10px' }}>
            <p><strong>Last Payment Amount:</strong> {formatNumber(data.last_payment_amount)}</p>
            <p><strong>Average Monthly Sales:</strong> {formatNumber(data.average_monthly_sales)}</p>
          </div>
          <div style={{ flex: '1 1 30%', margin: '10px' }}>
            <p><strong>Total Visits (YTD):</strong> {formatNumber(data.total_visits)}</p>
            <p><strong>Average Spend Per Visit:</strong> {formatNumber(data.average_spend_per_visit)}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="customer-aging-summary">
      <h2>Customer Balance Aging Summary</h2>
      <div className="export-section">
        <CSVLink
          data={csvData}
          filename={`customer_aging_summary.csv`}
          className="export-button"
        >
          Export to Excel
        </CSVLink>
      </div>

      <DataTable
        columns={columns}
        data={agingData}
        progressPending={isLoading}
        highlightOnHover
        pagination
        paginationPerPage={30}
        striped
        dense
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        footer={
          <div>
            <strong>Total Invoiced: {calculateTotals('INVOICED')}</strong> |
            <strong>Total Payment: {calculateTotals('PAYMENT')}</strong> |
            <strong>Total Balance: {calculateTotals('BALANCE')}</strong>
          </div>
        }
      />

      {/* Display totals row */}
      {agingData.length > 0 && (
        <div className="totals-row">
          <p>Total Older Than 3 Months: {calculateTotals('older_than_3_months_balance')}</p>
          <p>Total Older Than 2 Months: {calculateTotals('older_than_2_months_balance')}</p>
          <p>Total Older Than 1 Month: {calculateTotals('older_than_1_month_balance')}</p>
        </div>
      )}
    </div>
  );
};

export default CustomerAgingSummary;
