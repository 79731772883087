import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClipboardList, faCogs, faFileInvoiceDollar,faChartBar,faUserTie } from '@fortawesome/free-solid-svg-icons';
import '../styles/LandingPage.css'; // Add styles here
import { useAuth } from '../hooks/useAuth';

const LandingPage = () => {
  const {rolePermissions } = useAuth();

function MyLink (props) { 
  if(rolePermissions[props.path]?.View === 'true') {
   return (
  <Link to={props.path} className="link-card">
    <FontAwesomeIcon icon={props.iconname} size="3x" className="icon"/><p>{props.name}</p>
  </Link>);
  }
  //return <div></div>;
};


  return (
    <div className="landing-page">
      <div className="links-container">
        <MyLink path="/medical-reports" iconname={faClipboardList} name="Medical Reports"/>
        <MyLink path="/user-management" iconname={faUser} name="User Management"/>
        <MyLink path="/role-management" iconname={faCogs} name="Role Management"/>
        <MyLink path="/sales-transactions" iconname={faFileInvoiceDollar} name="Sales Transactions"/>
        <MyLink path="/sales-form" iconname={faFileInvoiceDollar} name="Sales Form"/>
        <MyLink path="/sales-queue" iconname={faClipboardList} name="Sales Queue"/>
        <MyLink path="/customer-invoice" iconname={faFileInvoiceDollar} name="Customer Invoice"/>
        <MyLink path="/doctor-sales-summary" iconname={faFileInvoiceDollar} name="Doctor Sales"/>
        <MyLink path="/customer-aging-summary" iconname={faChartBar} name="Customer Aging Summary"/>
        <MyLink path="/employee-salaries" iconname={faUserTie} name="Employee Salaries"/>
      
      </div>
    </div>
  );
};

export default LandingPage;
