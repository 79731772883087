import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import '../styles/EmployeeSalaries.css'; 

const EmployeeSalaries = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [payPeriod, setPayPeriod] = useState('');
  const [branch, setBranch] = useState('');
  const [paymentsData, setPaymentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);

  const payPeriods = [
    '2024 April', '2024 May', '2024 June', '2024 July', '2024 August', '2024 September', '2024 October', '2024 November', '2024 December',
    '2025 January', '2025 February', '2025 March', '2025 April', '2025 May', '2025 June', '2025 July', '2025 August', '2025 September',
    '2025 October', '2025 November', '2025 December',
  ];

  const branchOptions = ['Kissy', 'Poultney'];

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/employees/`);
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    fetchEmployees();
  }, []);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/employees/salaries`, {
        params: {
          employee_id: selectedEmployee || undefined,
          pay_period: payPeriod || undefined,
          branch: branch || undefined,
        },
      });
      setPaymentsData(response.data);

      const total = response.data.reduce((sum, record) => sum + parseFloat(record.total_salary), 0);
      setTotalPayment(total);

      const uniqueEmployees = new Set(response.data.map((record) => record.employee_name));
      setEmployeeCount(uniqueEmployees.size);

    } catch (error) {
      console.error('Error fetching employee payments:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { name: 'Employee', selector: (row) => row.employee_name, sortable: true },
    { name: 'Pay Period', selector: (row) => row.pay_period, sortable: true },
    { name: 'Contracted Salary', selector: (row) => row.salary, sortable: true },
    { name: 'Branch', selector: (row) => row.branch, sortable: true },
    { name: '# of Pmts', selector: (row) => row.number_of_payments, sortable: true },
    { name: 'Total Salary', selector: (row) => row.total_salary, sortable: true, right: true },
  ];

  // Define conditional row styles for highlighting
  const conditionalRowStyles = [
    {
      when: (row) => parseFloat(row.total_salary) > parseFloat(row.salary) * 1.03,
      style: {
        backgroundColor: 'rgba(255, 0, 0, 0.1)', // Light red background
        color: '#000', // Optional: Customize text color
      },
    },
  ];

  return (
    <div className="employee-salaries-container">
      <h2>Employee Salaries</h2>
      <div className="filter-form">
        <div className="form-group">
          <label>Employee:</label>
          <select value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}>
            <option value="">All Employees</option>
            {employees.map((employee) => (
              <option key={employee.employee_id} value={employee.employee_id}>
                {employee.employee_name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Pay Period:</label>
          <select value={payPeriod} onChange={(e) => setPayPeriod(e.target.value)}>
            <option value="">All Pay Periods</option>
            {payPeriods.map((period) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Branch:</label>
          <select value={branch} onChange={(e) => setBranch(e.target.value)}>
            <option value="">All Branches</option>
            {branchOptions.map((branchOption) => (
              <option key={branchOption} value={branchOption}>
                {branchOption}
              </option>
            ))}
          </select>
        </div>

        <div className="form-actions">
          <button onClick={handleSearch} disabled={isLoading}>
            {isLoading ? 'Searching...' : 'Search'}
          </button>
        </div>
      </div>

      <div className="data-table-container">
        <div className="summary-row">
          <strong>Total Payment: {totalPayment.toLocaleString(undefined, { minimumFractionDigits: 2 })}</strong> | 
          <strong> Employee Count: {employeeCount}</strong>
        </div>

        <DataTable
          columns={columns}
          data={paymentsData}
          pagination
          paginationPerPage={30}
          highlightOnHover
          progressPending={isLoading}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>

      <button className="export-button">Export to Excel</button>
    </div>
  );
};

export default EmployeeSalaries;
