import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from '../hooks/useAuth';
import { CSVLink } from "react-csv";
import SalesView from './SalesView';
import '../styles/SalesTransactions.css';

const SalesTransactions = () => {
  const { rolePermissions } = useAuth();
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [salesNumber, setSalesNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [patientInfo, setPatientInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [searchParams, setSearchParams] = useState(null);
  const [viewMode, setViewMode] = useState('list');
  const [initialSale, setInitialSale] = useState(null);
  const [selectedDatePeriod, setSelectedDatePeriod] = useState('This Month'); // New state for date period selection

  // Function to set date range based on selected period
  const updateDateRange = (period) => {
    const today = new Date();
    let start, end;

    switch (period) {
      case 'This Month':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = today;
        break;
      case 'Last Month':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'This Year':
        start = new Date(today.getFullYear(), 0, 1);
        end = today;
        break;
      case 'Last Year':
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      default:
        start = new Date();
        end = new Date();
    }

    setStartDate(start);
    setEndDate(end);
  };

  // Initialize default date range to "This Month"
  useEffect(() => {
    updateDateRange(selectedDatePeriod);
  }, [selectedDatePeriod]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_GET_DOCTORS}`)
      .then(response => {
        setDoctors(response.data.doctors);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching doctors:', error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (searchParams) {
      fetchReceipts();
    }
  }, [searchParams]);

  const fetchReceipts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_SALES_TRANSACTIONS}`, {
        params: searchParams
      });
      setReceipts(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching receipts:', error);
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    setSearchParams({
      doctorName: selectedDoctor,
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      salesNumber,
      customerName,
      patientInfo
    });
  };

  const handleDoctorChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  const handleDateChange = (setter) => (date) => {
    setter(date);
  };

  const handleView = (sale) => {
    setInitialSale(sale);
    setViewMode('view');
  };

  const handleClose = () => {
    setViewMode('list');
    setInitialSale(null);
  };

  const columns = [
    { name: 'Receipt/Invoice Number', selector: row => row.sales_number, sortable: true },
    { name: 'Patient Name', selector: row => row.patient_name, sortable: true },
    { name: 'Customer', selector: row => row.customer_name, sortable: true },
    { name: 'Status', selector: row => row.sales_status, sortable: true },
    { name: 'Date', selector: row => row.sales_date, sortable: true },
    { name: 'Consultation', selector: row => row.Consultation, sortable: true },
    { name: 'Lab and Medication', selector: row => row['Lab and Medication'], sortable: true },
    { name: 'Admission', selector: row => row.Admission, sortable: true },
    { name: 'Observation', selector: row => row.Observation, sortable: true },
    { name: 'Surgery', selector: row => row.Surgery, sortable: true },
    { name: 'Total', selector: row => row.sales_total, sortable: true },
    {
      name: 'Actions',
      cell: (row) => <button onClick={() => handleView(row)}>View</button>
    }
  ];

  const totals = receipts.reduce((acc, receipt) => {
    acc.Consultation += parseFloat(receipt.Consultation) || 0;
    acc.LabAndMedication += parseFloat(receipt['Lab and Medication']) || 0;
    acc.Admission += parseFloat(receipt.Admission) || 0;
    acc.Observation += parseFloat(receipt.Observation) || 0;
    acc.Surgery += parseFloat(receipt.Surgery) || 0;
    acc.Total += parseFloat(receipt.sales_total) || 0;
    return acc;
  }, {
    Consultation: 0,
    LabAndMedication: 0,
    Admission: 0,
    Observation: 0,
    Surgery: 0,
    Total: 0
  });

  const miniColumns = [
    { name: 'Sales Number', selector: row => row.sales_number, sortable: true },
    { name: 'Date', selector: row => row.sales_date, sortable: true },
    { name: 'Total', selector: row => row.sales_total, sortable: true }
  ];

  return (
    <div className="sales-transactions">
      {isLoading ? <p>Loading...</p> : (
        <div>
          {viewMode === 'list' ? (
            <>
              <div className="sales-transactions-header">
                <div className="filter-group">
                  <label htmlFor="date-period">Date Period:</label>
                  <select
                    id="date-period"
                    value={selectedDatePeriod}
                    onChange={(e) => setSelectedDatePeriod(e.target.value)}
                  >
                    <option value="This Month">This Month</option>
                    <option value="Last Month">Last Month</option>
                    <option value="This Year">This Year</option>
                    <option value="Last Year">Last Year</option>
                  </select>
                </div>
                <div className="filter-group">
                  <label htmlFor="start-date">Start Date:</label>
                  <div className="datepicker-container">
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange(setStartDate)}
                      dateFormat="yyyy-MM-dd"
                      id="start-date"
                      className="datepicker"
                    />
                  </div>
                </div>
                <div className="filter-group">
                  <label htmlFor="end-date">End Date:</label>
                  <div className="datepicker-container">
                    <DatePicker
                      selected={endDate}
                      onChange={handleDateChange(setEndDate)}
                      dateFormat="yyyy-MM-dd"
                      id="end-date"
                      className="datepicker"
                    />
                  </div>
                </div>
                <div className="filter-group">
                  <label htmlFor="doctor-select">Select Doctor:</label>
                  <select
                    id="doctor-select"
                    value={selectedDoctor}
                    onChange={handleDoctorChange}
                  >
                    <option value="">-- Select Doctor --</option>
                    {doctors.map((doctor) => (
                      <option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="filter-group small">
                  <label htmlFor="sales-number">Invoice/Receipt Number:</label>
                  <input
                    type="text"
                    id="sales-number"
                    value={salesNumber}
                    onChange={(e) => setSalesNumber(e.target.value)}
                    className="input-field"
                  />
                </div>
                <div className="filter-group small">
                  <label htmlFor="customer-name">Customer Name:</label>
                  <input
                    type="text"
                    id="customer-name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    className="input-field"
                  />
                </div>
                <div className="filter-group small">
                  <label htmlFor="patient-info">Patient Info:</label>
                  <input
                    type="text"
                    id="patient-info"
                    value={patientInfo}
                    onChange={(e) => setPatientInfo(e.target.value)}
                    className="input-field"
                  />
                </div>
                <div className="filter-group">
                  <button onClick={handleSearch}>Search</button>
                </div>
              </div>
              <div>
                <table className="totals-table">
                  <thead>
                    <tr><th colSpan={6}>Summary</th></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><b>Consultation:</b> {totals.Consultation.toLocaleString()}</td>
                      <td><b>Lab and Medication:</b> {totals.LabAndMedication.toLocaleString()}</td>
                      <td><b>Admission:</b> {totals.Admission.toLocaleString()}</td>
                      <td><b>Observation:</b> {totals.Observation.toLocaleString()}</td>
                      <td><b>Surgery:</b> {totals.Surgery.toLocaleString()}</td>
                      <td><b>Total:</b> {totals.Total.toLocaleString()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="sales-transactions-detail">
                <DataTable
                  title="Detail Sales Transactions"
                  columns={columns}
                  data={receipts}
                  pagination
                  paginationPerPage={50}
                  highlightOnHover
                  subHeader
                  subHeaderComponent={(
                    <div>
                      <CSVLink data={receipts} filename={"sales_transactions.csv"} className="btn btn-primary">
                        Export to CSV
                      </CSVLink>
                    </div>
                  )}
                />
              </div>
            </>
          ) : (
            <div className="transactions-and-form">
              <div className="transactions-list">
                <h2>Transactions</h2>
                <DataTable
                  columns={miniColumns}
                  data={receipts}
                  pagination
                  paginationPerPage={10}
                  highlightOnHover
                />
              </div>
              <div className="sales-form">
                <button className="close-button" onClick={handleClose}>X</button>
                {initialSale && <SalesView salesData={initialSale} />}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SalesTransactions;
